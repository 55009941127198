import React, { useCallback, useEffect, useRef, useState } from 'react'
import * as tapi from '@api/telegram'
import Header from './Header'
import { observer } from 'mobx-react-lite'
import telegramStore from '@store/telegram'
import { Tabs } from '../consts'
import ChatArea from '../ChatArea'
import useJoins from '../../../hooks/useJoins'
import JoinRow from './JoinRow'
import ScrollWrapper from './ScrollWrapper'
import Spinner from '@components/Spinner'
import HomeMessage from './HomeMessage'

const Joins = observer(({ id = null, watchlist = false }) => {

	const getQuery = () => {
		if (watchlist) return { page: 1, watchlist, id }
		return { page: 1, id }
	}
	
	const {
		data,
		triggerAutoScrollBottom,
		triggerAutoScrollTop,
		lastChildRef,
		chatLoading
	} = useJoins(
		telegramStore.activeChat?.id || 'activity',
		getQuery,
		'joinsQuery',
		telegramStore.isProfileTab()
	)

	const JoinsWrapper = ({ data }) => {
		return (
			<div className='flex flex-col gap-2'>
				{data.map((join, index) => (
					<div key={index} id={index} >
						<JoinRow
							lastChildRef={index === 2 ? lastChildRef : null}
							join={join}
						/>
					</div>
				))}

			</div>

		)
	}

	if (
		['profiles', 'myprofiles'].includes(telegramStore.getActiveTab().key) &&
		!telegramStore.activeChat?.id
	)
		return (
			<div className="flex flex-row items-center h-[95%]  w-full h-full main-bg-color">
				<div className="flex flex-col items-center w-full ">
						<div className="p-2 text-center mb-5 border-1 rounded-[50px] inner-bg-color">
						<div className="flex flex-row text-center items-center justify-center font-bold px-3">
							Select a profile to view joins
						</div>
					</div>
				</div>
			</div>
		)

	const joins = telegramStore.getJoins(telegramStore.activeChat?.id || 'activity')?.joins?.slice()?.reverse() ?? []
	if (chatLoading) return <Spinner />

	if (!joins) {
		return <div className="main-bg-color h-full w-full">no results</div>
	}
	return (
		<div
			id="tg-joins-layout"
			className="flex w-full flex-col main-bg-color relative  h-[98%] overflow-auto"
		>
			{/* <Header id={} title={' '} /> */}
			{joins?.length ? (
				<ScrollWrapper
					chatId={telegramStore.activeChat?.id || 'activity'}
					data={data.joins}
					triggerAutoScrollTop={triggerAutoScrollTop}
					triggerAutoScrollBottom={triggerAutoScrollBottom}
					chatLoading={chatLoading}
					queryKey='joinsQuery'
				>
					<JoinsWrapper data={joins} />
				</ScrollWrapper>
			) : (
				<HomeMessage text="No joins yet..." />
			)}

			{/* <ChatArea /> */}
			{/* <div className="flex flex-grow flex-col gap-2 overflow-auto">
					{messages.map((msg, index) => (
						<div
							key={index}
							className="p-2 text-white bg-[#17212b] rounded-[10px]"
						>
							{msg.text}
						</div>
					))}
				</div> */}
		</div>
	)
})

export default Joins
