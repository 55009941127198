import React, { useCallback, useEffect, useRef, useState } from 'react'
// import Message from './components/Message'
import * as helpers from '@api/helpers'
import { observer } from 'mobx-react-lite'
import telegramStore from '@store/telegram'
import Spinner from '@components/Spinner'
import { toJS } from 'mobx'
const ScrollWrapper = observer(
	({ chatId, data, chatLoading, children, queryKey }) => {
		const scrollContainerRef = useRef(null)
		const [scrollElementID, setScrollElementID] = useState(null)
		const [currentChatId, setCurrentChatId] = useState(chatId)

		const [triggerAutoScrollTop, setTriggerAutoScrollTop] = useState(false)
		const [triggerAutoScrollBottom, setTriggerAutoScrollBottom] =
			useState(false)
		const [init, setInit] = useState(true)

		useEffect(() => {
			setInit(true)
			setCurrentChatId(chatId)
		}, [chatId])


		useEffect(() => {
			return () => {
			  if (scrollContainerRef.current?.children?.length) {
				const firstEl = helpers.storeFirstElementInViewport(scrollContainerRef.current.children[0].children);
				telegramStore.setScrollPosition(queryKey, currentChatId, firstEl);
			  }
			};
		  }, [chatId, currentChatId]);


		useEffect(() => {
			if (!init) return
			// console.log(
			// 	'Chat changed ',
			// 	currentChatId,
			// 	telegramStore.getScrollPosition(queryKey, currentChatId) || 0
			// )
			const elementId = telegramStore.getScrollPosition(queryKey, currentChatId) || 0
			setScrollElementID(elementId)

			console.log(elementId)
			if (!elementId) {
				setTriggerAutoScrollBottom(true)
			} else {
				setTriggerAutoScrollTop(true)
			}

			
			// else {
			// }

			setInit(false)

		}, [init])



		// useEffect(() => {
		// 	console.log('trigger top')
		// 	setTriggerAutoScrollTop(true)
		// 	updateStore()
		// }, [telegramStore.getQuery(queryKey, currentChatId)?.page])


		// Save scroll position
		const updateStore = (pos) => {

		}

		// const getScrollPosition = () => {
		// 	return scrollElementID
		// }

		function scrollToPosition() {
			console.log('scrolling to ', scrollElementID, helpers.findElementById(scrollElementID))
			helpers.scrollToMessageWithId(
				scrollElementID,
				scrollContainerRef?.current,
				'instant'
			)

			// const distanceFromBottom = getScrollPosition()
			// // console.log(getScrollPosition())
			// const node = scrollContainerRef?.current
			// const scrollHeight = node.scrollHeight;
			// const clientHeight = node.clientHeight;
			// const desiredScrollTop = scrollHeight - clientHeight - distanceFromBottom;
			// node.scrollTop = desiredScrollTop;
		}

		useEffect(() => {
			if (!data?.length || !triggerAutoScrollBottom) return
			console.log('bot scroll ', 0)
			scrollContainerRef.current.scrollTop =
				scrollContainerRef.current.scrollHeight
			setTriggerAutoScrollBottom(false)
		}, [triggerAutoScrollBottom])




		// Auto scroll on prev position when user fetches new page of data
		useEffect(() => {
			// console.log(2)
			if (!triggerAutoScrollTop) return
			// console.log('top scroll ', scrollElementID, helpers.findElementById(scrollElementID)?.innerText.substring(0, 10))
			// console.log('top scroll ', scrollElementID, helpers.findElementById(scrollElementID)?.innerText)
			// updateStore()
			// const prevQuery = telegramStore.getQuery(queryKey, currentChatId)
			// if (!data?.length || prevQuery?.page < 2) return
			// const node = scrollContainerRef?.current
			scrollToPosition()
			setTriggerAutoScrollTop(false)
		}, [triggerAutoScrollTop])




		
		/**
		 * Effect to listen for user scrolling
		 * -- Stores current first visible project
		 * -- Appends new found pending projects when user goes on top
		 *
		 * */
		useEffect(() => {
			// console.log(3)
			if (!data?.length) return

			const func = (event) => {
				// const node = scrollContainerRef.current
				// if (node) {
				// 	const distanceToBottom =
				// 		node.scrollHeight - node.clientHeight - node.scrollTop
				// 	setDistanceToBottom(distanceToBottom)
				// 	// updateStore(distanceToBottom)
				// }
				// console.log(helpers.findElementById(helpers.storeFirstElementInViewport(
				// 	scrollContainerRef?.current.children[0].children
				// ))?.innerText)
			}

			func()

			scrollContainerRef?.current?.addEventListener('scroll', func)

			return () => {
				scrollContainerRef?.current?.removeEventListener('scroll', func)
			}
		}, [data])

		// useEffect(() => {
		// 	if (getScrollPosition() < 100) {
		// 		if (scrollContainerRef.current) {
		// 			scrollContainerRef.current.scrollTop =
		// 				scrollContainerRef?.current?.scrollHeight
		// 			setDistanceToBottom(0)
		// 			// updateStore(0)
		// 		}
		// 	}
		// }, [data])

		return (
			<div
				className="flex-1 overflow-y-auto p-4 relative "
				id="tg-scrollwrapper-wrapper-outer"
				ref={scrollContainerRef}
			>
				<div
					className="flex-grow gap-2 overflow-y-scroll  break-all no-scroll-bars w-full"
					id="tg-scrollwrapper-wrapper-inner"
				>
					{' '}
					{/* {children} */}
					{chatLoading || init ? <Spinner /> : children}
				</div>
			</div>
		)
	}
)

export default ScrollWrapper
