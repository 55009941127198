import { scrollIntoView } from "seamless-scroll-polyfill";

function removeDuplicates(arr) {
  return arr.filter((item, index) => arr.indexOf(item) === index);
}

export const appendNewData = (prev, curr) => {
  let new_arr = prev;

  if (!prev) return curr
  if (!curr) curr = []
  const prev_ids = prev.map((child) => child._id);
  const curr_ids = curr.map((child) => child._id);

  for (const [index, new_id] of curr_ids.entries()) {
    if (!prev_ids.includes(new_id)) {
      new_arr.push(curr[index]);
    }
  }

  return new_arr;
};

export const pageChangeOnly = (obj1, obj2) => {
  if (!obj1 && obj2) return false;
  // if (!obj1 || !obj2) return false
  let deep = _.cloneDeep([obj1, obj2]);
  delete deep[0].page;
  delete deep[1].page;
  return _.isEqual(deep[0], deep[1]);
};

export const unshiftNewChildren = (prev, curr) => {
  let new_arr = prev;
  const prev_ids = prev.map((child) => child._id);
  const curr_ids = curr.map((child) => child._id);

  for (const [index, new_id] of curr_ids.entries()) {
    if (!prev_ids.includes(new_id)) {
      new_arr.unshift(curr[index]);
    }
  }

  return new_arr;
};

export const hasNewData = (prev, curr) => {
  if (!prev) return true;
  let new_arr = prev;
  const prev_ids = prev.map((child) => child._id);
  const curr_ids = curr.map((child) => child._id);

  for (const [index, new_id] of curr_ids.entries()) {
    if (!prev_ids.includes(new_id)) {
      return true;
    }
  }

  return false;
};

export const scrollToMessageWithId = (id, containerId, behavior="smooth") => {
  const el = document.getElementById(id);
  const container = document.getElementById(containerId);
  if (el && container) {
    const topPos = el.getBoundingClientRect().top - container.getBoundingClientRect().top;
    container.scroll({ top: topPos, behavior });
  }  
}

export const scrollToProjectWithId = (id) => {
  const el = document.getElementById(id);
  if (el) {
    scrollIntoView(el, { behavior: "instant" });
  }
};

export const autoScroll = (el, y) => {
  // const el = document.getElementById(id)
  if (el) {
    el.scrollTo({
      top: y,
      left: 1000,
      behavior: "instant",
    });
  }
};

export const checkParentID = (e, id) => {
  return e.target.closest(id);
}

export const isInViewport = (element) => {
  if (!element) return false
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

/**
 * Saves state of first visible element
 *
 * */
export const storeFirstElementInViewport = (elements, setElementInViewport) => {
  if (elements) {
    const cardElements = Array.from(elements);

    for (const el of cardElements) {
      if (isInViewport(el)) {
        // first card in viewport
        return el.id
        // setElementInViewport(el._id);
        // return;
      }
    }
  }
};

export function findElementById(id) {
  const element = document.querySelector(`[ _id="${id}"]`);
  return element;
}

export function timeAgo(timestamp) {
  const time = new Date(timestamp * 1000);
  const now = new Date();
  const seconds = Math.round((now - time) / 1000);
  const minutes = Math.round(seconds / 60);
  const hours = Math.round(minutes / 60);
  const days = Math.round(hours / 24);
  const weeks = Math.round(days / 7);
  const months = Math.round(days / 30);
  const years = Math.round(days / 365);

  if (seconds < 60) {
      return `${seconds} sec`;
  } else if (minutes < 60) {
      return `${minutes} min`;
  } else if (hours < 24) {
      return `${hours} hour(s)  `;
  } else if (days < 7) {
      return `${days} day(s)  `;
  } else if (weeks < 4) {
      return `${weeks} Weeks  `;
  } else if (months < 12) {
      return `${months} Month  `;
  } else {
      return `${years} Year  `;
  }
}
