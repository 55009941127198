import React, { useCallback, useEffect, useRef, useState } from 'react'
import MessageWrapper from './MessageWrapper'
import * as tapi from '@api/telegram'
import * as helpers from '@api/helpers'
import Spinner from '@components/Spinner'
import { observer } from 'mobx-react-lite'
import telegramStore from '@store/telegram'
import * as _ from 'lodash'
import { debounce } from 'lodash'
import useMessages from '../../hooks/useMessages'
import ScrollWrapper from './components/ScrollWrapper'
import HomeMessage from './components/HomeMessage'
import { toJS } from 'mobx'

const ChatArea = observer(({ watchlist = false }) => {
	// const getQuery = () => {
	// 	if (telegramStore.tabType == 'profile' || watchlist)
	// 		return { page: 1, userId: telegramStore.activeChat?.id }
	// 	else return { page: 1, groupId: telegramStore.activeChat?.id }
	// }

	const getQuery = () => {
		const query = toJS(
			telegramStore.getMessagesQuery(telegramStore.activeChat?.id)
		) || { page: 1, groupId: telegramStore.activeChat?.id }
		// console.log('query', query)
		return query
	}

	const {
		data,
		triggerAutoScrollBottom,
		triggerAutoScrollTop,
		lastChildRef,
		chatLoading,
		setTriggerAutoScrollBottom
	} = useMessages(telegramStore.activeChat?.id || 'activity', getQuery, 'messagesQuery')

	if (!telegramStore.activeChat) {
		return (
			<div className="flex flex-row items-center h-[95%]  w-full h-full main-bg-color">
				<div className="flex flex-col items-center w-full ">
					<div className="p-2 text-center mb-5 border-1 rounded-[50px] inner-bg-color">
						<div className="flex flex-row text-center items-center justify-center font-bold px-3">
							Select a group to view messages
						</div>
					</div>
				</div>
			</div>
		)
	}

	const messages = data.messages?.slice()?.reverse() ?? []

	if (chatLoading) return <Spinner />
	// if (!telegramStore.activeChat?.id) return
	// console.log(triggerAutoScrollBottom, triggerAutoScrollTop)

	return (
		<div
			id="tg-chat-area"
			className="flex w-full flex-col main-bg-color relative  h-[99%] overflow-auto"
		>
			{messages?.length ? (
				<ScrollWrapper
					chatId={telegramStore.activeChat?.id || 'activity'}
					data={messages}
					queryKey="messagesQuery"
					triggerAutoScrollTop={triggerAutoScrollTop}
					setTriggerAutoScrollBottom={setTriggerAutoScrollBottom}
					triggerAutoScrollBottom={triggerAutoScrollBottom}
					chatLoading={chatLoading}
				>
					<MessageWrapper
						data={messages}
						lastChildRef={lastChildRef}
					/>
				</ScrollWrapper>
			) : (
				<HomeMessage text="No messages yet..." />
			)}
		</div>
	)
})

export default ChatArea
