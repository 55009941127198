import React from 'react'
import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx'
import telegramStore from '@store/telegram'
import { Tabs } from '../../consts'
import Spinner from '@components/Spinner'

const Divider = () => {
	return (
		// Adjust the gradient colors and shadow to match your design
		<div className="h-[3px] bg-gradient-to-r from-transparent via-black to-transparent shadow"></div>
	)
}

const Group = observer(({ group }) => {
	if (!group) return
	console.log(toJS(group))
	return (
		<button
			onClick={() => telegramStore.onChatClick(group, 'chat')}
			className="gap-4 items-center flex flex-row w-full"
		>
			<div className="relative h-8 w-8">
				<img
					className="h-8 w-8 rounded-full"
					alt={group.title} // Added alt attribute for accessibility
					src={`${process.env.REACT_APP_IMAGES_SERVER}/imgs/groups/${group.id}.jpg`}
				/>
				<span
					className={`absolute bottom-0 right-0 h-2 w-2 rounded-full ${
						group.active ? 'bg-green-500' : 'bg-red-500'
					}`}
				></span>
			</div>
			<div className="hover:scale-[1.05] transition-transform">
				{group.title}
			</div>
		</button>
	)
})

const GroupList = observer(({ groups }) => {
	if (!groups || !groups.length) return <div>no groups</div>

	return (
		<div className="flex flex-col w-full items-center">
			<div className="flex flex-row gap-2 items-center pb-4">
				{/* Optional icons or titles can be placed here */}
			</div>
			<div className="flex flex-col gap-2 items-left w-full">
				{groups.map((group) => (
					<Group key={group.id} group={group} />
				))}
			</div>
		</div>
	)
})

const Metric = (props) => {
	return (
		<div className="flex flex-row items-center gap-3">
			<props.icon />
			{props.value} {props.text}
		</div>
	)
}

const ProfileDetails = observer(() => {
	// console.log('members ', members)
	// console.log('details:', group)
	const profile = telegramStore.activeChat

	// console.log(groups)
	const groups = profile.groupDetails
	// console.log(toJS(groups))

	// console.log(toJS(profile))

	if (profile.id == 'All') return

	return (
		<div className="  inner-bg-color  h-full  shadow-md  flex flex-col px-3 ">
			{/* Sidebar content goes here */}
			<div className=" flex items-center text-white min-h-[50px] text-sm font-bold ">
				Profile Info
			</div>

			<div className="flex flex-col gap-4 overflow-auto">
				{/* Image, title, total members */}
				<div className=" flex flex-row gap-4 items-center pt-4">
					<img
						className="h-[70px] w-[70px] rounded-full "
						src={`${process.env.REACT_APP_IMAGES_SERVER}/imgs/users/${profile.id}.jpg`}
					/>
					<div className="flex flex-col  gap-2">
						<div className="font-bold text-[14px]">
							{profile.username}
						</div>
						<div className="text-xs opacity-50">
							{groups?.length} groups joined
						</div>
					</div>
				</div>

				<div className="">
					<div className="flex flex-col gap-4 ">
						<Divider />
						{!groups ? <Spinner /> : <GroupList groups={groups} />}
					</div>
				</div>

				<div className="h-[20px]"></div>
			</div>
		</div>
	)
})

export default ProfileDetails
