import React, { useState } from 'react'
import SideGroup from '../Layout/SideGroup'
import SideParent from '../Layout/SideParent'
import SideActivity from '../Layout/SideActivity'
import SearchBar from './components/SearchBarSimple'
import { observer } from 'mobx-react-lite'
import telegramStore from '@store/telegram'
import Filters from '@tg/components/Filters'
import { toJS } from "mobx"

const Menu = () => {
	const options = [
		{
			label: 'Option 1',
			key: 'opt'
		},
		{
			label: 'Option 1',
			key: 'opt'
		},
		{
			label: 'Option 1',
			key: 'opt'
		}
	]

	return (
		<div
			className="absolute top-4 right-4 inner-bg-color h-[300px] w-[180px] overflow-auto"
			style={{
				boxShadow:
					'inset 0 -3em 3em rgb(0 0 0 / 30%),  0.3em 0.3em 1em rgb(0 0 0 / 30%)'
			}}
		>
			<div className="flex flex-col py-2 px-4 gap-2 items-start ">
				{options.map((option) => (
					<button className="hover:tg-hover" key={option.key}>
						{option.label}
					</button>
				))}
			</div>
		</div>
	)
}

const Sidebar = observer((props) => {
	const [isMenuOpen, setIsMenuOpen] = useState(false)

	const text =
	telegramStore.isProfileTab()
			? telegramStore.groupsQuery.searchParent
			: telegramStore.groupsQuery.searchGroup

	const onSearchChange = (e) => {
		if (telegramStore.isProfileTab()) {
			telegramStore.setGroupsQuery({
				...telegramStore.groupsQuery,
				searchGroup: null,
				searchParent: e.target.value
			})
		} else {
			telegramStore.setGroupsQuery({
				...telegramStore.groupsQuery,
				searchParent: null,
				searchGroup: e.target.value
			})
		}
	}

	const ActivitySidebar = () => {
		return (
			<div>
				{/* <SideActivity
					data={{ key: 'all', label: 'All', id: 'all' }}
					onClick={props.onChatClick}
					/> */}
				<SideActivity
					data={{
						key: 'messages',
						label: 'Messages',
						id: 'messages'
					}}
					onClick={props.onChatClick}
				/>
				<SideActivity
					data={{ key: 'joins', label: 'Joins', id: 'joins' }}
					onClick={props.onChatClick}
				/>
			</div>
		)
	}

	// console.log(toJS(telegramStore.parents).map(parent => console.log(parent?.username)))

	const Profiles = observer(() => {
		if (!telegramStore.parents?.length) return

		return (
			<div>
				{/* <SideParent
					parent={{id: 'All'}}
					key={'All'}
					onClick={props.onChatClick}
				/> */}
				{telegramStore.parents.map((parent) => (
					<SideParent
						tabType='profiles'
						parent={parent}
						key={parent._id}
						onClick={props.onChatClick}
					/>
				))}
			</div>
		)
	})

	// console.log(telegramStore.watchlist.parents)
	return (
		<div
			id="tg-sidebar"
			className="inner-bg-color w-[350px] h-full overflow-hidden"
		>
			<div className="flex flex-row items-center pt-1">
				<SearchBar text={text} onChange={onSearchChange} />
				<Filters />
				{/* Uncomment and use this button if needed */}
				{/* <button onClick={() => setIsMenuOpen(prev => !prev)}>Toggle Menu</button> */}
			</div>
			<div id="sidebar-inner" className="relative h-full overflow-auto ">
				<div className="pb-16">
					<div className="w-full">
						{telegramStore.getActiveTab().key === 'chat' ? (
							telegramStore.groups.map((group) => (
								<SideGroup
									group={group}
									key={group._id}
									onClick={props.onChatClick}
								/>
							))
						) : telegramStore.getActiveTab().key === 'profiles' ? (
							<Profiles />
						) : telegramStore.getActiveTab().key ===
						  'myprofiles' ? (
							telegramStore.watchlist.parents.map((wl) => (
								<SideParent
									tabType='myprofiles'
									parent={wl.telegram}
									key={wl.telegram_id}
									onClick={props.onChatClick}
								/>
							))
						) : telegramStore.getActiveTab().key === 'mygroups' ? (
							telegramStore.watchlist.groups.map((wl) => (
								<SideGroup
									group={wl.telegram}
									key={wl.telegram_id}
									onClick={props.onChatClick}
								/>
							))
						) : telegramStore.getActiveTab().key === 'activity' ? (
							<ActivitySidebar />
						) : null}
					</div>
				</div>
			</div>
		</div>
	)
})

export default Sidebar
