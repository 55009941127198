import axios from './axios'

const GROUPS_URL = process.env.REACT_APP_API + '/groups'
const MESSAGES_URL = process.env.REACT_APP_API + '/messages'
const JOINS_URL = process.env.REACT_APP_API + '/joins'
const PARENTS_URL = process.env.REACT_APP_API + '/parents'
const MEMBERS_URL = process.env.REACT_APP_API + '/members'
const WATCHLIST_URL = process.env.REACT_APP_API + '/watchlist'
const ADD_CHILD_WATCHLIST_URL = process.env.REACT_APP_API + '/watchlist/add-child'
const ADD_PARENT_WATCHLIST_URL = process.env.REACT_APP_API + '/watchlist/add-parent'
const REMOVE_CHILD_URL = process.env.REACT_APP_API + '/watchlist/child/remove'
const REMOVE_PARENT_URL = process.env.REACT_APP_API + '/watchlist/parent/remove'
const CHILD_URL = process.env.REACT_APP_API + '/child'
const PARENT_GROUPS_URL = process.env.REACT_APP_API + '/parents/groups'



let groups_controller, messages_controller, joins_controller, group_parents_controller, parents_controller

export async function parentGroups(query) {
  group_parents_controller && group_parents_controller.abort()
  group_parents_controller = new AbortController()

  let config = {
    signal: group_parents_controller.signal,
    params: query,
    headers: {
      'Content-Type': 'application-json'
    }
  }
  return await axios.get(PARENT_GROUPS_URL, config).then((res) => {
    group_parents_controller = undefined
    return res
  })
}

export async function groups(query) {
  // groups_controller && groups_controller.abort()
  // groups_controller = new AbortController()

  let config = {
    // signal: groups_controller.signal,
    params: query,
    headers: {
      'Content-Type': 'application-json'
    }
  }
  return await axios.get(GROUPS_URL, config).then((res) => {
    groups_controller = undefined
    return res
  })
}


export async function messages(query) {
  messages_controller && messages_controller.abort()
  messages_controller = new AbortController()

  let config = {
    signal: messages_controller.signal,
    params: query,
    headers: {
      'Content-Type': 'application/json', // Correct header format
    }
  }
  return await axios.get(MESSAGES_URL, config).then((res) => {
    messages_controller = undefined
    return res
  })
}


export async function joins(query) {
  joins_controller && joins_controller.abort()
  joins_controller = new AbortController()

  let config = {
    signal: joins_controller.signal,
    params: query,
    headers: {
      'Content-Type': 'application-json'
    }
  }
  return await axios.get(JOINS_URL, config).then((res) => {
    joins_controller = undefined
    return res
  })
}

export async function parents(query) {
  parents_controller && parents_controller.abort()
  parents_controller = new AbortController()

  let config = {
    signal: parents_controller.signal,
    params: query,
    headers: {
      'Content-Type': 'application-json'
    }
  }
  return await axios.get(PARENTS_URL, config).then((res) => {
    parents_controller = undefined

    return res
  })
}


export async function members(query) {

  let config = {
    params: query,
    headers: {
      'Content-Type': 'application-json'
    }
  }
  return await axios.get(MEMBERS_URL, config).then((res) => {
    return res
  })
}


export async function watchlist(query) {

  let config = {
    params: query,
    headers: {
      'Content-Type': 'application-json'
    }
  }
  return await axios.get(WATCHLIST_URL, config).then((res) => {
    return res
  })
}

export async function addChildToWatchlist(data) {
  return await axios.post(ADD_CHILD_WATCHLIST_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application-json",
    },
    data
  });
}


export async function addParentToWatchlist(data) {
  return await axios.post(ADD_PARENT_WATCHLIST_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application-json",
    },
    data
  });
}

export async function removeChildFromWatchlist(data) {
  return await axios.post(REMOVE_CHILD_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application-json",
    },
    data
  });
}

export async function removeParentFromWatchlist(data) {
  return await axios.post(REMOVE_PARENT_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application-json",
    },
    data
  });
}

export async function child(data) {
  return await axios.get(CHILD_URL, {
    params: data,
    method: "Get",
    headers: {
      "Content-Type": "application-json",
    },
  });
}











