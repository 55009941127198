import React, { useCallback, useEffect, useRef, useState } from 'react'
import * as tapi from '@api/telegram'
import * as helpers from '@api/helpers'
import telegramStore from '@store/telegram'
import * as _ from 'lodash'
import { debounce } from 'lodash'
import { toJS } from 'mobx'

const useJoins = (chatId, getQuery, queryKey, isProfileTab=false) => {
	const key = 'joins'
	const INIT = { [key]: [], hasNext: undefined }
	const [triggerAutoScrollTop, setTriggerAutoScrollTop] = useState(true)
	const [triggerAutoScrollBottom, setTriggerAutoScrollBottom] = useState(true)
	const [chatLoading, setChatLoading] = useState(true)
	const [isFetching, setIsFetching] = useState(false) // New state to prevent multiple fetches
	const [init, setInit] = useState(true)
	const [triggerFetch, setTriggerFetch] = useState(true)

	const [data, setData] = useState(telegramStore.getJoins(chatId) || INIT)

	getQuery = () => {
		const query = toJS(telegramStore.getQuery(queryKey, chatId)) || { page: 1, id: telegramStore.activeChat?.id }
		// console.log('query', query)
		return query
	}
	
	// console.log(Object.fromEntries(toJS(telegramStore.joinsQuery)))

	useEffect(() => {
		try {
			if (!chatId) return
			telegramStore.setQuery(queryKey, chatId, getQuery())
			// if (telegramStore.getJoins(chatId)) {
			// 	// setData(telegramStore.getJoins(chatId))
			// 	return
			// }
			setChatLoading(true)
			setTriggerAutoScrollBottom(true)
			setTriggerFetch(prev => !prev)

			telegramStore.setJoins(INIT)
		} catch (e) {
			console.log(e)
		} finally {
			setInit(false)
		}
	}, [chatId])

	// useEffect(() => {
	// 	setTriggerFetch(prev => !prev)
	// 	console.log('')
	// }, [telegramStore.getMessagesQuery(chatId)?.text])

	useEffect(() => {
		if (isProfileTab && !chatId) {
			if (chatLoading) setChatLoading(false)
				return
			
		}
		if (init) {
			setChatLoading(false)
			return
		}

		const query = getQuery()
		
		if (isFetching || !query) return
		setIsFetching(true)
		
		// console.log('querying', isFetching, query)
		tapi.joins(query)
			.then((res) => {
				const prevData = _.cloneDeep(data[key])
				const newFixedData =
					query.page > 1
						? helpers.appendNewData(prevData, res.data.data[key])
						: res.data.data[key]

				const newData = {
					[key]: newFixedData,
					hasNext: res.data.data.hasNext,
					fetched: true
				}

				telegramStore.setJoins(
					chatId,
					newData
				)
				setData(newData)

				// Auto scroll
				if (query.page > 1) {
					setTriggerAutoScrollTop((prev) => !prev)
				} else {
					setTriggerAutoScrollBottom((prev) => !prev)
				}
			})
			.catch((e) => console.error(e))
			.finally(() => {
				setIsFetching(false)
				setChatLoading(false)
			})
		}, [triggerFetch])

	/**
	 * Intersection observer for infinite scrolling
	 *
	 * */
	const intObserver = useRef()
	const handleIntersection = useCallback(
		debounce((entries) => {
			if (entries[0].isIntersecting && data.hasNext && !isFetching) {
				const prevQuery = telegramStore.getQuery(queryKey, chatId)
				telegramStore.setQuery(queryKey, chatId, {
					...prevQuery,
					page: prevQuery.page + 1
				})
				setTriggerFetch(prev => !prev)
			}
		}, 500),
		[data.hasNext, isFetching]
	)

	const lastChildRef = useCallback(
		(node) => {
			if (!node || chatLoading || isFetching) return
			if (intObserver.current) intObserver.current.disconnect()
			intObserver.current = new IntersectionObserver(handleIntersection)
			intObserver.current.observe(node)
		},
		[chatLoading, isFetching, handleIntersection]
	)

	return {
		data,
		triggerAutoScrollBottom,
		triggerAutoScrollTop,
		lastChildRef,
		chatLoading
	}
}

export default useJoins
